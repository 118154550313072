<template>
  <main>
    <div class="uk-section section-home-banner">
        <div class="home-bg">
          <img src="/theme/banner-new.png" alt="png">
        </div>
        <div class="home-caption">
          <div class="uk-container">
            <div
              class="uk-grid-large uk-flex-middle uk-grid uk-grid-stack"
            >
              <div class="uk-width-1-2@m in-slide-text uk-first-column" style="z-index: 99;">
                <h1 class="uk-heading-small">
                  Best  trading 
                  <span class="in-highlight">community, </span> courses and  signals.
                </h1>
                <div class="uk-card uk-padding-small custom-share-referral">
                  <h4 class="uk-margin-small-bottom">Earn with us</h4>
                  <p class="uk-margin-remove">Share the link below and earn with every new user registered.</p>
                  <p class="uk-margin-small-top" v-html="user.referral_code"></p>
                  <p class="uk-margin-small-top uk-margin-remove-bottom">
                    <social-share :url="user.referral_code" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- section content begin -->
      <div class="uk-section uk-section-primary uk-preserve-color in-equity-1 uk-margin-remove-top">
        <div class="uk-container">
          <div class="uk-grid">
            <div class="uk-width-1-1">
              <h4>Our Services</h4>
            </div>
          </div>
          <div
            class="
              uk-grid-match
              uk-grid-medium
              uk-child-width-1-4@m
              uk-child-width-1-2@s
              uk-margin-bottom
              uk-grid
            "
            data-uk-grid=""
          >
            <div class="uk-first-column">
              <div
                class="uk-card uk-card-body uk-card-default uk-border-rounded"
              >
                <div class="uk-flex uk-flex-middle">
                  <span class="in-product-name red">1</span>
                  <h5 class="uk-margin-remove">Premium Signals</h5>
                </div>
                <p>
                  Get 2 daily signals sent directly to your email and our
                  exclusive telegram groups.
                </p>
                <router-link
                  :to="{ name: 'public.signals' }"
                  class="
                    uk-button
                    uk-button-text
                    uk-float-right
                    uk-position-bottom-right
                  "
                  >Explore<i
                    class="fas fa-arrow-circle-right uk-margin-small-left"
                  ></i
                ></router-link>
              </div>
            </div>
            <div>
              <div
                class="uk-card uk-card-body uk-card-default uk-border-rounded"
              >
                <div class="uk-flex uk-flex-middle">
                  <span class="in-product-name green">2</span>
                  <h5 class="uk-margin-remove">Learning</h5>
                </div>
                <p>
                  Join the academy to learn all about forex trading online --
                  from beginners to professionals.
                </p>
                <router-link
                  :to="{ name: 'public.academy' }"
                  class="
                    uk-button
                    uk-button-text
                    uk-float-right
                    uk-position-bottom-right
                  "
                  >Explore<i
                    class="fas fa-arrow-circle-right uk-margin-small-left"
                  ></i
                ></router-link>
              </div>
            </div>
            <div>
              <div
                class="uk-card uk-card-body uk-card-default uk-border-rounded"
              >
                <div class="uk-flex uk-flex-middle">
                  <span class="in-product-name blue">3</span>
                  <h5 class="uk-margin-remove">Community</h5>
                </div>
                <p>
                  Join the world's best forex trading community, with daily
                  discussions and live videos.
                </p>
                <router-link
                  :to="{ name: 'public.community' }"
                  class="
                    uk-button
                    uk-button-text
                    uk-float-right
                    uk-position-bottom-right
                  "
                  >Explore<i
                    class="fas fa-arrow-circle-right uk-margin-small-left"
                  ></i
                ></router-link>
              </div>
            </div>
            <div>
              <div
                class="uk-card uk-card-body uk-card-default uk-border-rounded"
              >
                <div class="uk-flex uk-flex-middle">
                  <span class="in-product-name">4</span>
                  <h5 class="uk-margin-remove">Elite Videos</h5>
                </div>
                <p>
                  Get access to over 100 + FREE premium videos on specialist
                  forex trading topics.
                </p>
                <router-link
                  :to="{ name: 'register' }"
                  class="
                    uk-button
                    uk-button-text
                    uk-float-right
                    uk-position-bottom-right
                  "
                  >Explore<i
                    class="fas fa-arrow-circle-right uk-margin-small-left"
                  ></i
                ></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- section content end -->

      <!-- section content begin -->
      <div
        class="
          uk-section
          in-equity-2
          uk-background-contain uk-background-center
        "
        data-src="/new/in-equity-2-bg.png"
        data-uk-img=""
        style="background-image: url('/new/in-equity-2-bg.png')"
      >
        <div class="uk-container uk-margin-top">
          <div class="uk-grid uk-flex uk-flex-center">
            <div class="uk-width-2xlarge@m uk-text-center">
              <span class="uk-label uk-label-warning"
                >Fast execution, low latency<i
                  class="fas fa-arrow-right fa-xs uk-margin-small-left"
                ></i
              ></span>
              <h1 class="uk-margin-top">
                Your premium choice for trading signals
              </h1>
              <p class="uk-text-lead uk-margin-medium-top">
                Harness the power of technology and community to make a quicker,
                smarter and more precise decision on CFD currency pairs.
              </p>
            </div>
            <div class="uk-width-2xlarge@m uk-margin-medium-top">
              <div
                class="
                  uk-grid
                  uk-child-width-1-4@m
                  uk-child-width-1-4@s
                  uk-text-center
                  in-feature-box
                "
                data-uk-grid=""
              >
                <router-link :to="{ name: 'register' }" class="uk-first-column">
                  <span class="in-icon-wrap">
                    <img
                      src="/new/in-equity-2-icon-1.svg"
                      data-src="/new/in-equity-2-icon-1.svg"
                      alt="icon-1"
                      width="35"
                      height="42"
                      data-uk-img=""
                    />
                  </span>
                  <p class="uk-margin-top">Trading calculators</p>
                </router-link>
                <router-link :to="{ name: 'register' }">
                  <span class="in-icon-wrap">
                    <img
                      src="/new/in-equity-2-icon-2.svg"
                      data-src="/new/in-equity-2-icon-2.svg"
                      alt="icon-2"
                      width="38"
                      height="42"
                      data-uk-img=""
                    />
                  </span>
                  <p class="uk-margin-top">Market analysis</p>
                </router-link>
                <router-link :to="{ name: 'register' }">
                  <span class="in-icon-wrap">
                    <img
                      src="/new/in-equity-2-icon-3.svg"
                      data-src="/new/in-equity-2-icon-3.svg"
                      alt="icon-3"
                      width="42"
                      height="42"
                      data-uk-img=""
                    />
                  </span>
                  <p class="uk-margin-top">Market reviews</p>
                </router-link>
                <router-link :to="{ name: 'register' }">
                  <span class="in-icon-wrap">
                    <img
                      src="/new/in-equity-2-icon-4.svg"
                      data-src="/new/in-equity-2-icon-4.svg"
                      alt="icon-4"
                      width="42"
                      height="42"
                      data-uk-img=""
                    />
                  </span>
                  <p class="uk-margin-top">Trading academy</p>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- section content end -->

      <!-- section content begin -->
      <div class="uk-section in-equity-3 in-offset-top-20">
        <div class="uk-container uk-margin-large-bottom">
          <div class="uk-grid uk-flex uk-flex-middle">
            <div class="uk-width-expand@m">
              <h1 class="uk-margin-small-bottom">
                Check Out Our
                <span class="in-highlight">Community</span>Membership Plans
              </h1>
              <ul class="uk-list in-list-check">
                <li>Free Advanced course</li>
                <li>Free trading signals</li>
                <li>Free trade discussions with senior traders</li>
                <li>Daily conference meetings</li>
                <li>Free correlation numbers</li>
                <li>Free post analysis video from our senior traders.</li>
                <li>Traders ( engagement) awards</li>
              </ul>
            </div>
            <div
              class="uk-width-2xlarge uk-flex uk-flex-right uk-flex-center@s"
            >
              <div
                class="
                  uk-card uk-card-body uk-card-default uk-border-rounded
                  in-margin-top-60@s
                "
              >
                <div id="tradingview-widget">
                  <div style="width: 100%">
                    <table class="pricing-table">
                      <tbody>
                        <tr>
                          <td
                            style="
                              border-bottom: solid 1px #ddd;
                              border-right: solid 1px #ddd;
                            "
                          >
                            <h3>1 Month</h3>
                            <h1>
                              $ {{ membership_plans[1].whole }}
                              <small>.{{ membership_plans[1].cent }}</small>
                            </h1>
                          </td>
                          <td style="border-bottom: solid 1px #ddd">
                            <h3>3 Months</h3>
                            <h1>
                              $ {{ membership_plans[3].whole }}
                              <small>.{{ membership_plans[3].cent }}</small>
                            </h1>
                          </td>
                        </tr>
                        <tr>
                          <td style="border-right: solid 1px #ddd">
                            <h3>6 Months</h3>
                            <h1>
                              $ {{ membership_plans[6].whole }}
                              <small>.{{ membership_plans[6].cent }}</small>
                            </h1>
                          </td>
                          <td>
                            <h3>12 Months</h3>
                            <h1>
                              $ {{ membership_plans[12].whole }}
                              <small>.{{ membership_plans[12].cent }}</small>
                            </h1>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="
          uk-section uk-section-primary uk-preserve-color
          in-equity-6
          uk-background-contain uk-background-center
        "
        data-src="/new/in-equity-decor-2.svg"
        data-uk-img=""
        style="background-image: url('/new/in-equity-decor-2.svg')"
      >
        <div class="uk-container uk-margin-small-bottom">
          <div class="uk-grid uk-flex uk-flex-center">
            <div class="uk-width-3-4@m uk-margin-top">
              <div
                class="
                  uk-grid
                  uk-child-width-1-3@s
                  uk-child-width-1-3@m
                  uk-text-center
                  in-register
                "
                data-uk-grid=""
              >
                <div class="uk-width-1-1 in-h-line uk-first-column">
                  <h2>What's Next</h2>
                </div>
                <div class="uk-grid-margin uk-first-column">
                  <span class="in-icon-wrap circle">1</span>
                  <p>Learn In The Academy</p>
                </div>
                <div class="uk-grid-margin">
                  <span class="in-icon-wrap circle">2</span>
                  <p>
                    Subscribe To Premium Signals
                  </p>
                </div>
                <div class="uk-grid-margin">
                  <span class="in-icon-wrap circle">3</span>
                  <p>Subscribe To The Community</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- section content end -->
    </main>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      membership_plans: { 1: {}, 3: {}, 6: {}, 12: {}},
    }
  },

  methods: {
    fetch() {
      this.$axios.get('/api/v1/home').then(response => {
        this.membership_plans = response.data.membership_plans
      })
    }
  },

  computed: {
    user() {
      return this.$store.getters.getAuthUser
    }
  }
}
</script>

<style lang="scss" scoped>
.uk-card-secondary.custom-share-referral {
  background: #000 !important;
  
  h4, p {
    color: #fff !important;
  }
}
</style>